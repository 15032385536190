<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header :shareBtn="true" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <div class="page-title-clear"></div>
    <div class="page-content" v-if="!isLoading">
      <!-- Company -->
      <div class="card card-style">
        <div class="align-self-center mt-5 text-center">
          <img :src="logoUrl" class="rounded-m me-3" width="80" />
          <p class="mb-0 mt-2 font-800">
            {{ companyProfile.business_name }}
          </p>
        </div>

        <div class="content">
          <p class="mb-2">
            {{ companyProfile.long_about }}
          </p>
          <p class="mb-2">
            {{ companyProfile.address }}, {{ city }}, {{ province }},
            {{ country }}
          </p>
          <p class="mb-2">
            {{ companyProfile.website }}
          </p>

          <div class="divider mt-3"></div>
          <a
            @click="connect(companyProfile.id)"
            href="#"
            class="btn btn-full btn-m font-600 rounded-s shadow-l"
            :class="{
              'bg-red-dark': disableBtn,
              'gradient-highlight': !disableBtn,
            }"
            >{{ btnText }}</a
          >
        </div>
      </div>

      <!-- End Company -->
    </div>

    <!--End applied modals -->
    <div
      id="emailVerifyNotif"
      class="menu menu-box-modal bg-red-dark rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-times-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <h1 class="text-center mt-3 text-uppercase color-white font-700">
        Wooops!
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Email anda belum terverifikasi<br />
        Silahkan verifikasi email terlebih dahulu
      </p>
      <a
        @click="menuClose"
        href="#"
        class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        "
        >Keluar</a
      >
    </div>
    <!-- connect modals -->
    <div
      id="connect"
      class="menu menu-box-modal rounded-m"
      data-menu-hide="1500"
      data-menu-width="250"
      data-menu-height="170"
    >
      <h1 class="text-center mt-3 pt-2">
        <i class="fa fa-check-circle scale-icon fa-3x color-green-dark"></i>
      </h1>
      <h3 class="text-center pt-2">Anda sekarang berhasil terhubung</h3>
    </div>
    <!-- End Connect modals-->
    <div
      id="user-type"
      class="menu menu-box-modal bg-red-dark rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-times-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <h1 class="text-center mt-3 text-uppercase color-white font-700">
        Wooops!
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        {{ msgUserType }}
      </p>
      <a
        @click="menuClose"
        href="#"
        class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        "
        >Keluar</a
      >
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "CompanyDetail",
  props: {
    job: Object,
  },
  inject: ["getApply"],
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      disableBtn: false,
      btnText: "Connect",
      companyProfile: "",
      isLoading: true,
      errors: [],
      apiUrl: "/api/v1/job-seeker/job/",
      isAuthenticated: this.$store.state.isAuthenticated,
      isVerified: this.$store.state.account.isVerified,
      userType: this.$store.state.account.userType,
      msgUserType: "",
      btnDisable:false,
    };
  },
  beforeMount() {},
  computed: {
    logoUrl() {
      return this.companyProfile.get_logo
        ? axios.defaults.baseURL + this.companyProfile.get_logo
        : "/images/defaults/default_logo.png";
    },
    city() {
      return this.companyProfile.city ? this.companyProfile.city.name : "";
    },
    province() {
      return this.companyProfile.province
        ? this.companyProfile.province.name
        : "";
    },
    country() {
      return this.companyProfile.country
        ? this.companyProfile.country.name
        : "";
    },
  },
  mounted() {
    init_template();
    this.checkUser();

    var companyId = this.$route.params.company_id;
    this.getData(companyId);
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuOpen,
    menuClose,
    checkUser() {
      console.log("this.userType");
      if (this.userType !== "J") {
        this.menuOpen("user-type");
        this.msgUserType =
          "Anda harus login sebagai pencari kerja untuk membuka halaman ini";
      }
    },
    /*company detail*/
    async getData(id) {
      await axios
        .get("/api/v1/employer/company/profile/detail/" + id + "/")
        .then((response) => {
          this.companyProfile = response.data.company_profile;
          console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data getApply : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error.message));
          }
        });
    },
    async connect(company_id) {
      const formData = {
        company_profile: company_id,
        connect: 1,
      };
      if(!this.btnDisable){
              await axios
        .post("/api/v1/job-seeker/connect/", formData)
        .then((response) => {
          console.log("response data ", response);
          if (response.data.status === "success") {
            this.menuOpen("connect");
            this.btnDisable = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });

      }

    },
  },
};
</script>

<style>
.text-block {
  white-space: pre; /*// or pre-line*/
}
</style>

